const API_BASE_URL = process.env.REACT_APP_API_HOST;

export const getTeacherCards = async (token) => {
  const response = await fetch(`${API_BASE_URL}/api/teacher/cards/`, {
    method: 'GET',
    headers: {
      'Authorization': `Bearer ${token}`,
    },
  });
  if (!response.ok) throw new Error('Errore nel recupero delle card del teacher');
  return response.json();
};

export const evaluateAnswer = async (token, answerId, evaluation) => {
  const response = await fetch(`${API_BASE_URL}/api/teacher/evaluate-answer/${answerId}/`, {
    method: 'POST',
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(evaluation),
  });
  if (!response.ok) throw new Error('Errore nella valutazione della risposta');
  return response.json();
};

export const chooseReferenceAnswer = async (token, cardId, answerId) => {
  const response = await fetch(`${API_BASE_URL}/api/teacher/choose-reference-answer/${cardId}/`, {
    method: 'POST',
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ answer_id: answerId }),
  });
  if (!response.ok) throw new Error('Errore nella scelta della risposta di riferimento');
  return response.json();
};