import React, { useState, useEffect, useContext } from 'react';
import AuthContext from '../context/AuthContext';
import Sidebar from '../components/Sidebar';
import { SidebarItem } from '../components/Sidebar';
import { HomeIcon, SettingsIcon } from 'lucide-react';
import { getProfile } from '../api/userApi';
import { useNavigate, useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from '../components/ui/breadcrumb';

const HomePage = ({children}) => {
  const { authTokens, logoutUser } = useContext(AuthContext);
  const [profile, setProfile] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();

  const currentPath = location.pathname;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const profileData = await getProfile(authTokens.access);
        setProfile(profileData);
      } catch (error) {
        console.error('Error fetching data:', error);
        if (error.response && error.response.status === 401) {
          logoutUser();
          navivate('/login');
        }
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [authTokens, logoutUser]);

  if (isLoading) {
    return <main>Loading...</main>;
  }

  if (!profile) {
    logoutUser();
    navigate('/login');
    return       <main className="flex-1 p-4 ml-16 p-4 md:p-6 overflow-auto">
Error in profile fetch.</main>;
  }

  return (
    <div>
      <Sidebar username={`${profile.first_name} ${profile.last_name}`} email={profile.email}>
        <SidebarItem icon={<HomeIcon size={20} />} text="Home" active={true} href="/"/>
        <SidebarItem icon={<SettingsIcon size={20} />} text="Settings" href="/" />
      </Sidebar>
      <main className="flex-1 p-4 ml-16 p-4 md:p-6 overflow-auto">

        <div>{children}</div>
      </main>
    </div>
  );
};

export default HomePage;