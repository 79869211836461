import React, { useState, useEffect, useContext } from 'react';
import { Card, CardContent, CardHeader, CardTitle } from "../../components/ui/card";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../../components/ui/tabs";
import { getStudentCourses, getStudentCards, getStudentProfile } from '../../api/studentApi';
import AuthContext from '../../context/AuthContext';
import { Link } from 'react-router-dom';
import { getProfile } from '../../api/userApi';

const StudentHomePage = () => {
  const [courses, setCourses] = useState([]);
  const [cards, setCards] = useState([]);
  const [profile, setProfile] = useState(null);
  const [loading, setLoading] = useState(true);
  const { authTokens, logoutUser } = useContext(AuthContext);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const profileData = await getProfile(authTokens.access);
        setProfile(profileData);
      } catch (error) {
        console.error('Error fetching data:', error);
        if (error.response && error.response.status === 401) {
          logoutUser();
          navigate('/login');
        }
      } 
    };

    fetchData();
  }, [authTokens, logoutUser]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!authTokens) {
          console.error("Token non disponibile");
          logoutUser();
          return;
        }

        const [coursesData, cardsData, profileData] = await Promise.all([
          getStudentCourses(authTokens.access),
          getStudentCards(authTokens.access),
          getStudentProfile(authTokens.access)
        ]);
        setCourses(coursesData);
        setCards(cardsData);
        setProfile(profileData);
      } catch (error) {
        console.error("Errore nel caricamento dei dati:", error);
        if (error.response && error.response.status === 401) {
          logoutUser();
        }
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [authTokens, logoutUser]);

  if (loading) return <div>Caricamento in corso...</div>;

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">Welcome, {profile?.first_name}!</h1>
      
      <Tabs defaultValue="courses">
        <TabsList>
          <TabsTrigger value="courses">Corsi</TabsTrigger>
          <TabsTrigger value="cards">Card</TabsTrigger>
          <TabsTrigger value="profile">Profilo</TabsTrigger>
        </TabsList>
        
        <TabsContent value="courses">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            {courses.map(course => (
              <Card key={course.id}>
                <CardHeader>
                  <CardTitle>{course.name}</CardTitle>
                </CardHeader>
                <CardContent>
                  <p>{course.description}</p>
                </CardContent>
              </Card>
            ))}
          </div>
        </TabsContent>
        
        <TabsContent value="cards">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            {cards.map(card => (
              <Link to={`/card-process/${card.id}`} style={{ textDecoration: 'none', color: 'inherit' }}>
  <Card key={card.id}>
    <CardHeader>
      <CardTitle>{card.question.text}</CardTitle>
    </CardHeader>
    <CardContent>
      <p>current_phase: {card.current_phase}</p>
      <p>Scadenza: {new Date(card.due_date_first_answer).toLocaleDateString()}</p>
    </CardContent>
  </Card>
</Link>
            ))}
          </div>
        </TabsContent>
        
        <TabsContent value="profile">
          <Card>
            <CardHeader>
              <CardTitle>Il tuo profilo</CardTitle>
            </CardHeader>
            <CardContent>
              <p>Nome: {profile?.first_name} {profile?.last_name}</p>
              <p>Email: {profile?.email}</p>
              <p>Ruolo: {profile?.is_teacher ? 'Insegnante' : 'Studente'}</p>
            </CardContent>
          </Card>
        </TabsContent>
      </Tabs>
    </div>
  );
};

export default StudentHomePage;