import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import AuthContext from '../../context/AuthContext';
import { getTeacherCards } from '../../api/teacherApi';
import HomePage from '../HomePage';
import { getProfile } from '../../api/userApi';

import { Table, TableBody, TableCaption, TableCell, TableHead, TableHeader, TableRow } from "../../components/ui/table";
import { Button } from "../../components/ui/button";
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from "../../components/ui/card";
import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle, DialogTrigger } from "../../components/ui/dialog";
import { Avatar, AvatarFallback, AvatarImage } from "../../components/ui/avatar";
import { Badge } from "../../components/ui/badge";
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from "../../components/ui/collapsible";
import { ThumbsUp, ThumbsDown, Meh } from 'lucide-react';

const TeacherHomePage = () => {
  const { authTokens, logoutUser } = useContext(AuthContext);
  const [cards, setCards] = useState([]);
  const [selectedCard, setSelectedCard] = useState(null);
  const [profile, setProfile] = useState(null);

  const navigate = useNavigate();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const profileData = await getProfile(authTokens.access);
        setProfile(profileData);
      } catch (error) {
        console.error('Error fetching data:', error);
        if (error.response && error.response.status === 401) {
          logoutUser();
          navivate('/login');
        }
      } 
    };

    fetchData();
  }, [authTokens, logoutUser]);
  useEffect(() => {
    const fetchCards = async () => {
      try {
        const data = await getTeacherCards(authTokens.access);
        setCards(data);
      } catch (error) {
        console.error('Error fetching cards:', error);
        if (error.response && error.response.status === 401) {
          logoutUser();
          navigate('/login');
        }
      }
    };

    fetchCards();
  }, [authTokens, logoutUser, navigate]);

  const evaluationIcons = {
    VPOS: <ThumbsUp className="w-4 h-4 text-green-500" />,
    POS: <ThumbsUp className="w-4 h-4 text-blue-500" />,
    NEUT: <Meh className="w-4 h-4 text-gray-500" />,
    NEG: <ThumbsDown className="w-4 h-4 text-orange-500" />,
    VNEG: <ThumbsDown className="w-4 h-4 text-red-500" />
  };

  const renderStudentProcess = (session, profile) => (
    <Card className="mb-4">
      <CardHeader>
        <CardTitle>{session.student.user.username}</CardTitle>
      </CardHeader>
      <CardContent>
        <h3 className="font-semibold mb-2">First Answer</h3>
        <p>{session.first_answer?.text}</p>
        
        {session.first_answer?.comments.length > 0 && (
          <Collapsible open={true}>
            <CollapsibleTrigger className="text-sm text-blue-500 hover:underline mt-2">
              Hide {session.first_answer.comments.length} comments
            </CollapsibleTrigger>
            <CollapsibleContent>
              {session.first_answer.comments.map((comment, index) => (
                <div key={index} className="bg-gray-50 p-2 rounded mt-2 text-xs">
                  <div className="flex justify-between items-center mb-1">
                    <span className="font-semibold">{comment.peer.username}</span>
                    <div className="flex space-x-2">
                      <Badge variant="secondary" className="flex items-center">
                        Form {evaluationIcons[comment.form_evaluation]}
                      </Badge>
                      <Badge variant="secondary" className="flex items-center">
                        Content {evaluationIcons[comment.content_evaluation]}
                      </Badge>
                    </div>
                  </div>
                  <p>{comment.text}</p>
                </div>
              ))}
            </CollapsibleContent>
          </Collapsible>
        )}

        {session.review && (
          <>
            <h3 className="font-semibold mb-2 mt-4">Review</h3>
            <p>{session.review.text}</p>
          </>
        )}
      </CardContent>
    </Card>
  );

  return (
    <HomePage>
      <h2 className="text-2xl font-bold mb-4">Cards for Evaluation</h2>
      <Table>
        <TableCaption>A list of cards ready for teacher evaluation.</TableCaption>
        <TableHeader>
          <TableRow>
            <TableHead>Card ID</TableHead>
            <TableHead>Question</TableHead>
            <TableHead>Students</TableHead>
            <TableHead>Actions</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {cards.map((card) => (
            <TableRow key={card.id}>
              <TableCell>{card.id}</TableCell>
              <TableCell>{card.question.text}</TableCell>
              <TableCell>{card.sessions.length}</TableCell>
              <TableCell>
                <Dialog>
                  <DialogTrigger asChild>
                    <Button variant="outline" onClick={() => setSelectedCard(card)}>View Details</Button>
                  </DialogTrigger>
                  <DialogContent className="max-w-4xl">
                    <DialogHeader>
                      <DialogTitle>Card Details</DialogTitle>
                      <DialogDescription>
                        Question: {selectedCard?.question.text}
                      </DialogDescription>
                    </DialogHeader>
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
                      {selectedCard?.sessions.map((session) => (
                        <div key={session.id}>
                          {renderStudentProcess(session, profile)}
                        </div>
                      ))}
                    </div>
                  </DialogContent>
                </Dialog>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </HomePage>
  );
};

export default TeacherHomePage;