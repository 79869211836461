import { createContext, useState, useEffect } from 'react';
import { jwtDecode } from 'jwt-decode';

import { useNavigate } from 'react-router-dom';

const AuthContext = createContext();

export default AuthContext;

export const AuthProvider = ({ children }) => {
  let [user, setUser] = useState(() =>
    localStorage.getItem('authTokens') ? jwtDecode(localStorage.getItem('authTokens')) : null
  );
  let [authTokens, setAuthTokens] = useState(() =>
    localStorage.getItem('authTokens') ? JSON.parse(localStorage.getItem('authTokens')) : null
  );
  let [loading, setLoading] = useState(true);
  const API_BASE_URL = process.env.REACT_APP_API_HOST

  const navigate = useNavigate();
  let loginUser = async e => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_HOST}/api/token/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          username: e.target.username.value,
          password: e.target.password.value,
        }),
      });
      
      if (response.status === 200) {
        let data = await response.json();
        if (data) {
          localStorage.setItem('authTokens', JSON.stringify(data));
          setAuthTokens(data);
          setUser(jwtDecode(data.access));
          navigate('/');
        } else {
          throw new Error('Something went wrong during login.');
        }
      } else {
        let errorData = await response.json();
        if (errorData.detail === 'No active account found with the given credentials') {
          throw new Error('No account found with given credentials.');
        } else {
          throw new Error('Error during Login');
        }
      }
    } catch (error) {
      throw error;
    }
  };
  let loginGoogleUser = async (data) => {
    try {
      localStorage.setItem('authTokens', JSON.stringify(data));
      setAuthTokens(data);
      setUser(jwtDecode(data.access));
      navigate('/');
    } catch (error) {
      throw error;
    }
  }
  let logoutUser = e => {
    localStorage.removeItem('authTokens');
    setAuthTokens(null);
    setUser(null);
    navigate('/login');
  };

  const updateToken = async () => {
    const response = await fetch(`${process.env.REACT_APP_API_HOST}/api/token/refresh/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ refresh: authTokens?.refresh }),
    });

    const data = await response.json();
    if (response.status === 200) {
      setAuthTokens(data);
      setUser(jwtDecode(data.access));
      localStorage.setItem('authTokens', JSON.stringify(data));
    } else {
      logoutUser();
    }

    if (loading) {
      setLoading(false);
    }
  };

  let contextData = {
    user: user,
    authTokens: authTokens,
    loginUser: loginUser,
    logoutUser: logoutUser,
    loginGoogleUser: loginGoogleUser
  };

  useEffect(() => {
    const REFRESH_INTERVAL = 1000 * 60 * 4; // 4 minutes
    let interval = setInterval(() => {
      if (authTokens) {
        updateToken();
      }
    }, REFRESH_INTERVAL);
    return () => clearInterval(interval);
  }, [authTokens]);

  return <AuthContext.Provider value={contextData}>{children}</AuthContext.Provider>;
};
