import { MoreVertical, ChevronLast, ChevronFirst } from 'lucide-react';
import { useContext, createContext, useState } from 'react';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from './ui/dropdown-menu';
import { Button } from './ui/button';
import AuthContext from '../context/AuthContext';
import { Link } from 'react-router-dom';

const SidebarContext = createContext();

export default function Sidebar({ children, username, email }) {
  const [expanded, setExpanded] = useState(false);
  let { logoutUser } = useContext(AuthContext);

  return (
    <aside
      className={`h-screen ${
        expanded ? 'w-64' : 'w-16'
      } transition-all duration-300 fixed left-0 top-0 z-50`}

    >
      <nav className="h-full flex flex-col bg-[#152454] shadow-lg text-white">
        <div className="p-4 pb-2 flex justify-between items-center">
          <Link to="/">
            <img
              src="/logo-white-peercards.png"
              className={`overflow-hidden transition-all ${expanded ? 'w-32' : 'w-0'}`}
              alt=""
            />
          </Link>

          <button
            onClick={() => setExpanded(curr => !curr)}
            className="p-1.5 rounded-lg bg-[#1E2A4A] hover:bg-[#2C3E6E] text-white"
          >
            {expanded ? <ChevronFirst /> : <ChevronLast />}
          </button>
        </div>
        <SidebarContext.Provider value={{ expanded }}>
          <ul className="flex-1 px-3">{children}</ul>
        </SidebarContext.Provider>

        <div className="border-t border-[#1E2A4A] flex p-3">
          <img
            src={`https://ui-avatars.com/api/?background=457B9D&color=ffffff&bold=true&name=${username}`}
            alt=""
            className="w-10 h-10 rounded-md"
            onClick={() => setExpanded(curr => !curr)}

          />
          <div
            className={`
              flex justify-between items-center
              overflow-hidden transition-all ${expanded ? 'w-52 ml-3' : 'w-0'}
          `}
          
          >
            <div className="leading-4">
              <h4 className="font-semibold">{username}</h4>
              <span className="text-xs text-gray-300">{email}</span>
            </div>
            <DropdownMenu >
              <DropdownMenuTrigger asChild>
                <Button variant="ghost" size="icon" className="text-white hover:bg-[#1E2A4A]">
                  <MoreVertical size={20} />
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent align="end" className="bg-[#1E2A4A] text-white border-[#457B9D]">
                <DropdownMenuItem onClick={logoutUser} className="hover:bg-[#2C3E6E]">Logout</DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        </div>
      </nav>
    </aside>
  );
}

export function SidebarItem({ icon, text, active, alert, href }) {
  const { expanded } = useContext(SidebarContext);

  return (
    <Link to={href}>
      <li
        className={`
          relative flex items-center py-2 px-3 my-1
          font-medium rounded-md cursor-pointer
          transition-colors group
          ${
            active
              ? 'bg-[#1E2A4A] text-white'
              : 'hover:bg-[#1E2A4A] text-gray-300'
          }
      `}
      >
        {icon}
        <span className={`overflow-hidden transition-all ${expanded ? 'w-52 ml-3' : 'w-0'}`}>
          {text}
        </span>
        {alert && (
          <div
            className={`absolute right-2 w-2 h-2 rounded bg-[#457B9D] ${expanded ? '' : 'top-2'}`}
          />
        )}

        {!expanded && (
          <div
            className={`
            absolute left-full rounded-md px-2 py-1 ml-6
            bg-[#1E2A4A] text-white text-sm
            invisible opacity-20 -translate-x-3 transition-all
            group-hover:visible group-hover:opacity-100 group-hover:translate-x-0
        `}
          >
            {text}
          </div>
        )}
      </li>
    </Link>
  );
}