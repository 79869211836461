import React, { useState, useEffect, useContext, Profiler, useRef } from 'react';
import { format, differenceInHours, differenceInMinutes } from 'date-fns';
import { it } from 'date-fns/locale';
import AuthContext from '../context/AuthContext';
import { getCardDetails, submitAnswer, submitComment, submitReview, submitCommentEvaluation, getCardStatus } from '../api/studentApi';
import { Button } from "../components/ui/button"
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from "../components/ui/card"
import { Input } from "../components/ui/input"
import { Label } from "../components/ui/label"
import { Textarea } from "../components/ui/textarea"
import { Avatar, AvatarFallback, AvatarImage } from "../components/ui/avatar"
import { Progress } from "../components/ui/progress"
import { useParams } from 'react-router-dom';
import { Smile, ThumbsUp, ThumbsDown, Frown, Meh, PenTool, BookOpen, Send, User, Clock, StepForward } from 'lucide-react';
import { enGB } from 'date-fns/locale';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../components/ui/select"
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from "../components/ui/collapsible"
import { Badge } from "../components/ui/badge"
import { getProfile } from '../api/userApi';
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from '../components/ui/breadcrumb';
import { cn } from "../components/ui/lib/utils";
import { Link } from 'react-router-dom';
import { Copy } from 'lucide-react';
import { Alert, AlertDescription, AlertTitle } from "../components/ui/alert"
import { AlertDialog, AlertDialogAction, AlertDialogCancel, AlertDialogContent, AlertDialogDescription, AlertDialogFooter, AlertDialogHeader, AlertDialogTitle, AlertDialogTrigger } from "../components/ui/alert-dialog"
import { Maximize2 } from 'lucide-react';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogTrigger } from "../components/ui/dialog"

import {  Users,  Edit, GraduationCap, CheckSquare, Brain } from 'lucide-react';
import {  Loader } from 'lucide-react';

const ProcessPage = () => {
const { cardId } = useParams();
const [profile, setProfile] = useState(null);

  const { authTokens, user, logoutUser } = useContext(AuthContext);
  const [cardData, setCardData] = useState(null);
  const [cardState, setCardState] = useState(null);
  const [answer, setAnswer] = useState('');
  const [comment, setComment] = useState('');
  const [review, setReview] = useState('');
  const [commentEvaluations, setCommentEvaluations] = useState({});
  const [formEvaluation, setFormEvaluation] = useState('NEUT');
  const [contentEvaluation, setContentEvaluation] = useState('NEUT');
  
  const reviewRef = useRef(null);
  const phaseIcons = {
    FA: <PenTool className="h-4 w-4 mr-2" />,
    CO: <Users className="h-4 w-4 mr-2" />,
    ES: <ThumbsUp className="h-4 w-4 mr-2" />,
    RE: <Edit className="h-4 w-4 mr-2" />,
    ET: <GraduationCap className="h-4 w-4 mr-2" />,
    AC: <CheckSquare className="h-4 w-4 mr-2" />,
    SC: <Brain className="h-4 w-4 mr-2" />
  };
  
  const phaseLabels = {
    FA: 'First Answer',
    CO: 'Collaboration',
    ES: 'Evaluation student',
    RE: 'Review',
    ET: 'Evaluation Teacher',
    AC: 'Answer Choice',
    SC: 'SmartCard'
  };
  const EnumAnswerEvaluation = {
    VNEG: 'Very negative',
    NEG: 'Negative',
    NEUT: 'Neutral',
    POS: 'Positive',
    VPOS: 'Very positive'
  };
  const evaluationIcons = {
    VPOS: <ThumbsUp className="ml-1 w-4 h-4 text-green-500" />,
    POS: <ThumbsUp className="ml-1 w-4 h-4 text-blue-500" />,
    NEUT: <Meh className="ml-1 w-4 h-4 text-gray-500" />,
    NEG: <ThumbsDown className="ml-1 w-4 h-4 text-orange-500" />,
    VNEG: <ThumbsDown className="ml-1 w-4 h-4 text-red-500" />
  };
  const evaluationColors = {
    ML: 'border-red-500',
    HP: 'border-green-500',
    IS: 'border-blue-500',
    IN: 'border-purple-500',
  };
  const evaluationLabels = {
    ML: 'Misleading',
    HP: 'Helpful',
    IS: 'Insightful',
    IN: 'Inspiring',
  };
  const [isSubmittingComment, setIsSubmittingComment] = useState(false);
  const currentUserNoAction = () => {
    if (currentPhase === 'ET') return true;
  
    const peerSessionsInCO = cardData.sessions.filter(
      (session) => session !== currentUserSession && session.current_phase === 'CO'
    );
  
    const hasCommentedAllPeers = peerSessionsInCO.every((session) =>
      session.first_answer.comments.some((comment) => comment.peer.id === user.user_id)
    );
  
    const allOtherUsersInFA = cardData.sessions.every(
      (session) => session === currentUserSession || session.current_phase === 'FA'
    );
  
    return hasCommentedAllPeers || (allOtherUsersInFA && currentPhase !== 'FA');
  };
  
  const handleSubmitCommentWithLoading = async (answerId) => {
    setIsSubmittingComment(true);
    try {
      await handleSubmitComment(answerId);
    } finally {
      setIsSubmittingComment(false);
    }
  };
  const fetchCardData = async () => {
    try {
      const data = await getCardDetails(authTokens.access, cardId);
      setCardData(data);
      setCardState(data.state);

    } catch (error) {
      console.error('Errore nel recupero dei dati della card:', error);
    }
  };
  useEffect(() => {
    const fetchData = async () => {
        try {
          const profileData = await getProfile(authTokens.access);
          setProfile(profileData);
        } catch (error) {
          console.error('Error fetching data:', error);
          if (error.response && error.response.status === 401) {
            logoutUser();
            navigate('/login');
          }
        } 
      };

    fetchData();
    fetchCardData();
    

  }, [cardId, authTokens, logoutUser, review]);

  useEffect(() => {
    const interval = setInterval(async () => {
      try {
        const response = await getCardStatus(authTokens.access, cardId);
        if (response.state !== cardState) {
          setCardState(response.state);
          fetchCardData(); 
        }
      } catch (error) {
        console.error('Error fetching card data:', error);
      }
    }, 10000); 

    return () => {
      clearInterval(interval);
    };
  }, [cardId, cardState]);


  useEffect(() => {
    if (reviewRef.current && cardData && cardData.session && cardData.session.first_answer) {
      reviewRef.current.value = cardData.session.first_answer.text || '';
    }
  }, [cardData]);
  const getCurrentUserSession = (sessions, userId) => {
    return sessions.find(session => session.user.id === userId);
  };
  const handleSubmitAnswer = async () => {
    try {
      await submitAnswer(authTokens.access, cardId, answer);
      const updatedData = await getCardDetails(authTokens.access, cardId);
      setCardData(updatedData);
    } catch (error) {
      console.error('Errore nell\'invio della risposta:', error);
    }
  };

  const handleSubmitComment = async (answerId) => {
    try {
      await submitComment(authTokens.access, answerId, comment, formEvaluation, contentEvaluation);
      const updatedData = await getCardDetails(authTokens.access, cardId);
      setCardData(updatedData);
    } catch (error) {
      console.error('Errore nell\'invio del commento:', error);
    }
  };

  const handleSubmitReview = async () => {
    try {
      await submitReview(authTokens.access, cardId, review);
      const updatedData = await getCardDetails(authTokens.access, cardId);
      setCardData(updatedData);
    } catch (error) {
      console.error('Errore nell\'invio della revisione:', error);
    }
  };

  const handleSubmitCommentEvaluation = async (commentId, evaluation) => {
    try {
      await submitCommentEvaluation(commentId, evaluation, authTokens.access,cardId);
      const updatedData = await getCardDetails(authTokens.access, cardId);
      setCardData(updatedData);
    } catch (error) {
      console.error('Error submitting comment evaluation:', error);
    }
  };

  const setCommentEvaluation = (commentId, value) => {
    setCommentEvaluations(prevState => ({
      ...prevState,
      [commentId]: value
    }));
  };

  const sortSessions = (sessions, currentUserId) => {
    return sessions.sort((a, b) => {
      if (a.user.id === currentUserId) return -1;
      if (b.user.id === currentUserId) return 1;
      return 0;
    });
  };


  if (!cardData) return <div>Loading</div>;
  const styles = `
  @keyframes bump {
    0%, 100% { transform: scale(1); }
    50% { transform: scale(1.05); }
  }
  .bump-animation:hover {
    animation: bump 0.3s ease-in-out;
  }
  `;

  cardData.sessions = sortSessions(cardData.sessions, user.user_id);
  const currentUserSession = getCurrentUserSession(cardData.sessions, user.user_id);
  const currentPhase = currentUserSession ? currentUserSession.current_phase : null;
  const renderStudentColumn = (session, currentUser) => {
  const isCurrentUser = session === currentUserSession
  const canComment = !isCurrentUser && session.current_phase === 'CO' && 
  !session.first_answer.comments.some(comment => comment.peer.id === currentUser.id) ;
  if (currentPhase === 'FA' && !isCurrentUser) {
    return null;
  }
    return (
        <Card className="w-full max-w-3xl mt-3 mx-auto">
          <CardHeader className="flex items-center space-x-4">
            <Avatar>
              <AvatarFallback>{`${session.user.first_name.charAt(0)}${session.user.last_name.charAt(0)}`.toUpperCase()}</AvatarFallback>
            </Avatar>
            <div>
              <p className="text-sm font-medium">{`${session.user.first_name} ${session.user.last_name}`}</p>
            </div>
          </CardHeader>
          <CardContent className="p-4">
          
             {     session.first_answer ? (
                        <div className="bg-white shadow-md rounded-lg p-4 mb-4">
                          <p className={`${
                            session.current_phase !== 'FA' && session.current_phase !== 'CO' && session.current_phase !== 'RE'
                              ? 'text-lg'
                              : 'text-sm'
                          } text-gray-700`}>
                            {session.first_answer.text}
                          </p>            
                          {session.first_answer.comments.map((comment, index) => (
                            <span key={index}>
                          {comment.commentevaluation_set.length > 0 && (
                            <div className={`mt-2 border-2 rounded-lg text-center  ${evaluationColors[comment.commentevaluation_set[0].evaluation]}`}>
                              <p className="text-sm">{evaluationLabels[comment.commentevaluation_set[0].evaluation]}</p>
                            </div>
                          ) }
                              <div className="bg-gray-50 p-2 rounded mt-2 text-xs border-2 rounded-lg">
                                <div className="flex justify-between items-center mb-1">
                                  <span className="font-semibold">{comment.owner.username}</span>
                                  <div className="flex space-x-2">
                                    <Badge variant="secondary" className="flex items-center">
                                      Form {evaluationIcons[comment.form_evaluation]}
                                    </Badge>
                                    <Badge variant="secondary" className="flex items-center">
                                      Content {evaluationIcons[comment.content_evaluation]}
                                    </Badge>
                                  </div>
                                </div>
                                <p>{comment.text}</p>
                                {comment.commentevaluation_set.length <= 0 &&(
                                      currentPhase === "ES" && isCurrentUser && (
                                        <>
                                        <div className="flex items-center mb-6 mt-6">
                                        <Smile className="mr-3" size={32} />
                                        <div className="relative bg-blue-800 rounded-lg p-3 ml-2">
                                          <div className="absolute w-3 h-3 bg-blue-800 transform rotate-45 left-[-6px] top-1/2 -translate-y-1/2"></div>
                                          <p className="text-sm text-white">Evaluate the comment</p>
                                        </div>
                                      </div>
                                        <div className="flex items-center mt-2">
                                          <Select
                                            onValueChange={(value) => setCommentEvaluation(comment.id, value)}
                                            defaultValue={commentEvaluations[comment.id] || ""}
                                            className="w-[180px]"
                                          >
                                            <SelectTrigger>
                                              <SelectValue placeholder="Value the comment" />
                                            </SelectTrigger>
                                            <SelectContent>
                                              <SelectItem value="ML">Misleading</SelectItem>
                                              <SelectItem value="HP">Helpful</SelectItem>
                                              <SelectItem value="IS">Insightful</SelectItem>
                                              <SelectItem value="IN">Inspiring</SelectItem>
                                            </SelectContent>
                                          </Select>
                                          <Button
                                            onClick={() => handleSubmitCommentEvaluation(comment.id, commentEvaluations[comment.id])}
                                            className="ml-2"
                                            disabled={!commentEvaluations[comment.id]}
                                          >
                                            Confirm
                                          </Button>
                                        </div>
                                        </>
                                      )
                                    )}
                              </div>
                            </span>
                          ))}
                          
                  {session.review && (
                    <div className="bg-blue-50 shadow-md rounded-lg p-4 mt-4">
                      <h3 className="font-semibold mb-2">Review</h3>
                      <p className={`${
                session.current_phase !== 'FA' && session.current_phase !== 'CO' && session.current_phase !== 'RE'
                  ? 'text-sm'
                  : 'text-lg'
              } text-gray-700`}>
                {session.review.text}
              </p>
                      { currentPhase === 'ET' && (
                      <div className="flex items-center mt-4">
                        <ThumbsUp className="text-green-500 mr-2" size={24} />
                        <div className="bg-green-100 rounded-lg p-2">
                          <p className="text-sm text-green-800">The reviewed answer has been sent to the teacher for evaluation!</p>
                        </div>
                      </div>
                      )}
                    </div>
                  )}
                          
                          {canComment &&(
                            <div className="bg-gray-50 p-4 rounded-lg mt-4">

                              <div className="grid grid-cols-2 gap-2 mb-2">
                                <div>
                                  <Label className="flex items-center mb-2">
                                    <PenTool className="w-4 h-4 mr-1" /> Style
                                  </Label>
                                  <Select onValueChange={setFormEvaluation} defaultValue={formEvaluation}>
                                    <SelectTrigger>
                                      <SelectValue placeholder="Select style evaluation" />
                                    </SelectTrigger>
                                    <SelectContent>
                                      {Object.entries(EnumAnswerEvaluation).map(([key, value]) => (
                                        <SelectItem key={key} value={key}>
                                          <div className="flex items-center">
                                            {evaluationIcons[key]}
                                            <span className="ml-2">{value}</span>
                                          </div>
                                        </SelectItem>
                                      ))}
                                    </SelectContent>
                                  </Select>
                                </div>
                                <div>
                                  <Label className="flex items-center mb-2">
                                    <BookOpen className="w-4 h-4 mr-1" /> Content
                                  </Label>
                                  <Select onValueChange={setContentEvaluation} defaultValue={contentEvaluation}>
                                    <SelectTrigger>
                                      <SelectValue placeholder="Select content evaluation" />
                                    </SelectTrigger>
                                    <SelectContent>
                                      {Object.entries(EnumAnswerEvaluation).map(([key, value]) => (
                                        <SelectItem key={key} value={key}>
                                          <div className="flex items-center">
                                            {evaluationIcons[key]}
                                            <span className="ml-2">{value}</span>
                                          </div>
                                        </SelectItem>
                                      ))}
                                    </SelectContent>
                                  </Select>
                                </div>

                              </div>
                              <Textarea
                                value={comment}
                                onChange={(e) => setComment(e.target.value)}
                                placeholder="Your comment"
                                className="mb-5"
                              />
                            
          <Button 
            onClick={() => handleSubmitCommentWithLoading(session.first_answer.id)} 
            className="flex items-center"
            disabled={isSubmittingComment}
          >
            {isSubmittingComment ? (
              <Loader className="w-4 h-4 mr-1 animate-spin" />
            ) : (
              <Send className="w-4 h-4 mr-1" />
            )}
            {isSubmittingComment ? 'Sending...' : 'Send comment'}
          </Button>
                            </div>
                          )}
                        </div>
                      ) : (
                        !isCurrentUser && (
                        <div className="text-yellow-600 bg-yellow-50 p-4 rounded-lg mb-4">
                          <b>Waiting for {`${session.user.first_name} ${session.user.last_name}`} answer to be able to comment.</b>
                          <p>
                            Remaining time: {differenceInHours(new Date(cardData.due_date_first_answer), new Date())} hours and {differenceInMinutes(new Date(cardData.due_date_first_answer), new Date()) % 60} minutes
                          </p>
                        </div>
                      ))}
{
                      currentPhase === 'FA' && isCurrentUser && (
                          <>
                          <div className="flex items-center mb-6">
                            <Smile className="mr-3" size={32} />
                            <div className="relative bg-blue-800 rounded-lg p-3 ml-2">
                              <div className="absolute w-3 h-3 bg-blue-800 transform rotate-45 left-[-6px] top-1/2 -translate-y-1/2"></div>
                              <p className="text-sm text-white">Answer the question</p>
                            </div>
                          </div>
                          <Textarea
                            id="answer"
                            value={answer}
                            onChange={(e) => setAnswer(e.target.value)}
                            placeholder="Your answer ..."
                            className="mb-4"
                          />
                          <div className="flex justify-end">
                            <Button onClick={handleSubmitAnswer} className="mt-4">
                              Send answer
                            </Button>
                          </div>
                        </>
                        
                      )}

                        {        
                      currentPhase === 'RE' && isCurrentUser && (
                        <>
                          <Label htmlFor="review">Change your answer based on the comments received</Label>
                          
                          <div className="flex items-center space-x-2 mb-2">
                            <Textarea
                              id="review"
                              value={review}
                              onChange={(e) => setReview(e.target.value)}
                            />
                            <Button 
                              onClick={() => setReview(session.first_answer.text)}
                              variant="outline"
                              size="icon"
                              title="Copy first answer"
                            >
                              <Copy className="h-4 w-4" />
                            </Button>
                            <Dialog>
                              <DialogTrigger asChild>
                                <Button variant="outline" size="icon" title="Edit in full screen">
                                  <Maximize2 className="h-4 w-4" />
                                </Button>
                              </DialogTrigger>
                              <DialogContent className="max-w-[90vw] w-full max-h-[90vh] h-full">
                                <DialogHeader>
                                  <DialogTitle>Edit Review</DialogTitle>
                                  <Button 
                                    onClick={() => setReview(session.first_answer.text)}
                                    variant="outline"
                                    size="icon"
                                    title="Copy first answer"
                                  >
                              <Copy className="h-4 w-4" />
                            </Button>
                                </DialogHeader>
                                <Textarea
                                  className="min-h-[70vh]"
                                  value={review}
                                  onChange={(e) => setReview(e.target.value)}
                                />
                              </DialogContent>
                            </Dialog>
                          </div>
                          <AlertDialog>
                            <AlertDialogTrigger asChild>
                              <Button className="mt-2">Send review</Button>
                            </AlertDialogTrigger>
                            <AlertDialogContent>
                              <AlertDialogHeader>
                                <AlertDialogTitle>Sei sicuro di voler inviare la review?</AlertDialogTitle>
                                <AlertDialogDescription>
                                  Una volta inviata, non potrai più modificarla.
                                </AlertDialogDescription>
                              </AlertDialogHeader>
                              <AlertDialogFooter>
                                <AlertDialogCancel>Annulla</AlertDialogCancel>
                                <AlertDialogAction onClick={handleSubmitReview}>Invia</AlertDialogAction>
                              </AlertDialogFooter>
                            </AlertDialogContent>
                          </AlertDialog>
                        </>
                      )
                    }
                    

            
            
          </CardContent>
          <CardFooter className="text-xs text-gray-500 flex justify-between items-center">
          <div className="flex items-center">
              <StepForward className="w-4 h-4 mr-1" />
              <div className="flex items-center">
                {phaseIcons[session.current_phase]}
                <p>Current step: {phaseLabels[session.current_phase]}</p>
              </div>
          </div>
          <div className="flex items-center">
              <Clock className="w-4 h-4 mr-1" />
              <p>Deadline: {format(new Date(cardData.due_date_first_answer), "d MMMM yyyy 'at' HH:mm", { locale: enGB })}</p>
          </div>
          </CardFooter>
        </Card>
    )
  }
  return (
    
    <div className="container mx-auto px-4">
            <Breadcrumb className="mb-4">
              <BreadcrumbList>
                <BreadcrumbItem>
                  <Link to="/">
                    Cards
                  </Link>
                </BreadcrumbItem>
                <BreadcrumbSeparator />
                <BreadcrumbItem>
                {cardData.question.text}
                  </BreadcrumbItem>
                
              </BreadcrumbList>
          </Breadcrumb>
      <Card className="w-full mb-4">
        <CardHeader>
          <CardTitle className="text-2xl font-bold">{cardData.question.text}</CardTitle>
          <CardDescription>
            Course: {cardData.question.course.name} - Theme: {cardData.question.theme.name}
          </CardDescription>
        </CardHeader>
        <CardFooter>
          <p>Deadline: {format(new Date(cardData.due_date_first_answer), "d MMMM yyyy 'alle' HH:mm", { locale: it })}</p>
        </CardFooter>
      </Card>
      
      <div className={`grid gap-4 ${
        cardData.sessions.length === 1 ? 'grid-cols-1' :
        cardData.sessions.length === 2 ? 'grid-cols-1 md:grid-cols-2' :
        cardData.sessions.length === 3 ? 'grid-cols-1 md:grid-cols-2 lg:grid-cols-3' :
        'grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4'
      }`}> 
             {cardData.sessions.map((session) => (
          <div key={session.id}>
            {renderStudentColumn(session, user)}
          </div>
        ))}
      </div>

      {currentUserNoAction() && (
  <>
    <style>{styles}</style>
    <hr className='mt-5 mb-5 p-5' />
    <div className="flex justify-center items-center w-full m-4">
      <Link to="/" className="group">
        <div className={cn(
          "flex items-center bg-blue-800 rounded-lg p-3 transition-all duration-300 ease-in-out",
          "hover:shadow-lg bump-animation"
        )}>
          <Smile className="mr-3 text-white" size={22} />
          <p className="text-sm text-white">Continue other peercards! </p>
        </div>
      </Link>
    </div>
  </>
)}
    </div>
  );
};

export default ProcessPage;