import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import AuthContext from '../context/AuthContext';

const Header = () => {
  let { user, logoutUser } = useContext(AuthContext);

  return (
    <div className="flex ml-16 p-4  justify-end">
      <i>PeerCards</i> - v.0.1.0 
    </div>
  );
};

export default Header;
